import * as React from "react";
import { StringStateFunction } from "../types";
import useEmployee from "../hooks/useEmployee";

interface EmployeeContextInterface {
  currentEmployeeId: string;

  setCurrentEmployeeId: StringStateFunction;

  status: string;
  data: any;
}

const employeeContextDefaultValue: EmployeeContextInterface = {
  currentEmployeeId: "",
  setCurrentEmployeeId: () => {
    // this is intentional for initializing context
  },
  status: "",
  data: {}
};

const EmployeeContext = React.createContext<EmployeeContextInterface>(employeeContextDefaultValue);

const EmployeeProvider: React.FC = ({ children }) => {
  const [currentEmployeeId, setCurrentEmployeeId] = React.useState("");
  const { useGetEmployee } = useEmployee();
  const { status, data } = useGetEmployee();

  return (
    <EmployeeContext.Provider
      value={{
        currentEmployeeId,
        setCurrentEmployeeId,
        status,
        data
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
};

export { EmployeeContext, EmployeeProvider };
