import axios from "axios";
import { firebaseAuth } from "../../config/firebase";
import { GIFT_CARDS } from "../../utils/constants";
import { Employee, HistoryLog } from "../../models";
import { NotAuthenticatedError } from "../../types";

interface SignInOrSignUpResult {
  message: string;
  status: string;
  flag: string;
}

export default class EmployeeAPI {
  static async signInOrSignUpEmployeeWithPhoneNumber(): Promise<SignInOrSignUpResult> {
    const query = `${process.env.REACT_APP_BASE_URL}/employees`;
    const user = firebaseAuth.currentUser;
    if (!user) throw new NotAuthenticatedError();
    const token = await user.getIdToken(true);
    const { data } = await axios.get(query, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return data as SignInOrSignUpResult;
  }

  static async getEmployee(): Promise<Employee> {
    const query = `${process.env.REACT_APP_BASE_URL}/employees/firebase`;
    const user = firebaseAuth.currentUser;
    if (!user) throw new NotAuthenticatedError();
    const token = await user.getIdToken(true);
    const { data } = await axios.get(query, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return data as Employee;
  }

  static async updateEmployee({ update }: { update: any }): Promise<void> {
    const query = `${process.env.REACT_APP_BASE_URL}/employees/firebase`;
    const user = firebaseAuth.currentUser;
    if (!user) throw new NotAuthenticatedError();
    const token = await user.getIdToken(true);
    await axios.put(query, update, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  static async getHistoryLog(): Promise<HistoryLog[]> {
    const query = `${process.env.REACT_APP_BASE_URL}/employees/activity-log`;
    const user = firebaseAuth.currentUser;
    if (!user) throw new NotAuthenticatedError();
    const token = await user.getIdToken(true).catch((err) => console.log(err));
    const { data } = await axios.get(query, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return data.activity as HistoryLog[];
  }

  static flagHistoryLog = async (transactionId: string) => {
    // flag by transactionId
    const query = `${process.env.REACT_APP_BASE_URL}/employees/${transactionId}/flag`;
    const user = firebaseAuth.currentUser;
    if (!user) throw new NotAuthenticatedError();
    else {
      const token = await user.getIdToken(true);
      const { data } = await axios.post(
        query,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return data;
    }
  };

  static async activateLog({ timestamp }: { timestamp: string }): Promise<void> {
    const query = `${process.env.REACT_APP_BASE_URL}/employees/log`;
    const user = firebaseAuth.currentUser;
    if (!user) throw new NotAuthenticatedError();
    const token = await user.getIdToken(true).catch((err) => console.log(err));
    await axios.post(
      query,
      { timestamp },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  }

  // 返さないようにする
  static async redeemPoints({
    amount,
    email = null,
    giftCardType = GIFT_CARDS.AMAZON_GIFT_CARD,
    other = null
  }: {
    amount: number;
    email?: string | null;
    giftCardType: string;
    other?: string | null;
  }): Promise<any> {
    const query = `${process.env.REACT_APP_BASE_URL}/employees/redeem`;
    const user = firebaseAuth.currentUser;
    if (!user) throw new NotAuthenticatedError();
    const token = await user.getIdToken(true);
    const { data } = await axios.post(
      query,
      {
        amount,
        email,
        giftCardType,
        other
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    return data;
  }

  static async closeChristmasModal() {
    const query = `${process.env.REACT_APP_BASE_URL}/employees/christmas`;
    const user = firebaseAuth.currentUser;
    if (user) {
      const token = await user.getIdToken(true);
      const { data } = await axios.post(
        query,
        {
          closeChristmasModal: true
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return data;
    }
    throw new NotAuthenticatedError();
  }
}
