import React from "react";
import ReactDOM from "react-dom";
import { createTheme } from "@mui/material/styles";
import { BrowserRouter, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ThemeProvider } from "@mui/styles";

import App from "./App";
import { reportWebVitals } from "./utils";
import { AuthProvider, EmployeeProvider } from "./context";
import "./config/global.sass";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#00B7CE"
    }
  },
  typography: {
    fontFamily: `"Helvetica Neue", "Helvetica", "Arial", "sans-serif"`
  }
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          <AuthProvider>
            <EmployeeProvider>
              <App />
              <ReactQueryDevtools />
            </EmployeeProvider>
          </AuthProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </ThemeProvider>
  </QueryClientProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
