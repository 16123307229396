import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import { AuthContext } from "../context";

/**
 * Route wrapper for protected routes:
 *  redirect users to auth page if not authenticated.
 *  remember previous path to redirect users back after successful authentication.
 * Code from React Router documentation
 *  https://reactrouter.com/web/example/auth-workflow
 */
const PrivateRoute = ({ children, ...rest }: any) => {
  const { user } = React.useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={() =>
        user.uid ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: `/auth/signin`
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
