import * as React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
// @ts-ignore
import loadable from "@loadable/component";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import { AuthContext } from "./context";
import LoadingLogoPage from "./pages/LoadingLogoPage";
import PrivateRoute from "./components/PrivateRoute";
import ChannelService from "./config/channel";

// enable code splitting
const HomePage = loadable(() => import("./pages/HomePage"));
const CreateProfilePage = loadable(() => import("./pages/CreateProfilePage"));
// @ts-ignore
const RedeemPage = loadable(() => import("./pages/RedeemPage"));
const HistoryPage = loadable(() => import("./pages/HistoryPage"));
const NotFound = loadable(() => import("./pages/NotFoundPage"));
const EditProfilePage = loadable(() => import("./pages/EditProfilePage"));
const EditHobbyPage = loadable(() => import("./pages/EditHobbyPage"));
const AuthPhoneNumberPage = loadable(() => import("./pages/AuthPhoneNumberPage"));
const AuthOTPPage = loadable(() => import("./pages/AuthOTPPage"));

const App = (): JSX.Element => {
  const { pathname } = useLocation();
  const { globalLoading } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (!isMobile) {
      window.location.replace("https://respo.info");
    }
  }, []);

  React.useEffect(() => {
    if (pathname === "/") {
      ChannelService.boot({
        pluginKey: process.env.REACT_APP_CHANNEL_PLUGIN_KEY
      });
    } else {
      ChannelService.shutdown();
    }
  }, [pathname]);

  return (
    <div className="body-wrapper">
      <Helmet>
        <title>Loading...</title>
      </Helmet>
      {!globalLoading ? (
        <Switch>
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
          <Route path="/auth/signin/confirm-otp" component={AuthOTPPage} />
          <Route path="/auth/signin" component={AuthPhoneNumberPage} />
          <PrivateRoute exact path="/initial">
            <CreateProfilePage />
          </PrivateRoute>
          <PrivateRoute exact path="/redeem">
            <RedeemPage />
          </PrivateRoute>
          <PrivateRoute exact path="/history">
            <HistoryPage />
          </PrivateRoute>
          <PrivateRoute exact path="/edit-hobby">
            <EditHobbyPage />
          </PrivateRoute>
          <PrivateRoute path="/edit">
            <EditProfilePage />
          </PrivateRoute>
          <PrivateRoute exact path="/">
            <HomePage />
          </PrivateRoute>
          <Route component={NotFound} />
        </Switch>
      ) : (
        <LoadingLogoPage />
      )}
    </div>
  );
};

export default App;
