import * as React from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../context";
import EmployeeAPI from "../libs/api/employee";

export default function useEmployee() {
  const useGetEmployee = () => {
    const { user } = React.useContext(AuthContext);
    const { pathname } = useLocation();
    const isEnabled = !!user?.uid && !pathname.includes("auth");
    return useQuery(["employeeByFirebaseUuid", user?.uid], () => EmployeeAPI.getEmployee(), {
      enabled: isEnabled
    });
  };

  const useGetEmployeeActivityLog = () => {
    const { user } = React.useContext(AuthContext);
    const isEnabled = !!user?.uid;
    return useQuery(
      ["employeeActivityLogByFirebaseUuid", user?.uid],
      () => EmployeeAPI.getHistoryLog(),
      { enabled: isEnabled }
    );
  };

  return {
    useGetEmployee,
    useGetEmployeeActivityLog
  };
}
