export const FIELD_REQUIRED = "入力してください";

// messages for phone number input
export const INVALID_PHONE_NUMBER_MESSAGE = "有効な電話番号を入力してください";

// messages for otp confirmation
export const INVALID_OTP_MESSAGE = "有効なワンタイムパスワードが必要です";

// messages for shopId
export const INVALID_SHOPID_MESSAGE = "有効な店舗を選択してください。";

// messages for creating profile information
export const INVALID_NICKNAME_MESSAGE = "有効なニックネームを入力してください";
export const INVALID_COMMENT_MESSAGE = "有効なコメントを入力してください";

// messages for creating employee
export const INVALID_OTUID_MESSAGE = "有効な初回ユーザーIDを入力してください";

// messages for withdraw page
export const INVALID_WITHDRAW_AMOUNT = "700pt以上の数字を入力してください";
export const INVALID_EMAIL = "有効なメールアドレスを入力してください";

export const HOBBY_LIST = [
  { label: "🤣お笑い", value: "comedy" },
  { label: "🎤カラオケ", value: "karaoke" },
  { label: "🍺ビール", value: "beer" },
  { label: "🍶日本酒", value: "sake" },
  { label: "🍷ワイン", value: "wine" },
  { label: "🥃ウィスキー", value: "whiskey" },
  { label: "🍾シャンパン", value: "champagne" },
  { label: "🍻お酒", value: "alcohol" },
  { label: "👾ゲーム", value: "games" },
  { label: "🏔アウトドア", value: "outdoor" },
  { label: "🏠インドア", value: "indoor" },
  { label: "⚾️野球", value: "baseball" },
  { label: "⚽️サッカー", value: "soccer" },
  { label: "⛳️ゴルフ", value: "golf" },
  { label: "🏀バスケ", value: "basketball" },
  { label: "🎾テニス", value: "tennis" },
  { label: "🚗車", value: "cars" },
  { label: "📚読書", value: "books" },
  { label: "✈️旅行", value: "travel" },
  { label: "🎬映画", value: "movies" },
  { label: "😽猫派", value: "cat" },
  { label: "🐶犬派", value: "dog" },
  { label: "📸カメラ", value: "camera" },
  { label: "🀄️麻雀", value: "mahjong" },
  { label: "♦️ポーカー", value: "poker" },
  { label: "🎲ボードゲーム", value: "boardgames" },
  { label: "🔤多言語", value: "multilingual" },
  { label: "💉済", value: "vaccinated" },
  { label: "🥷アニメ", value: "anime" },
  { label: "📖漫画", value: "manga" },
  { label: "🍣寿司", value: "sushi" },
  { label: "🍖肉", value: "meat" },
  { label: "🐟海鮮", value: "seafood" },
  { label: "🍜ラーメン", value: "ramen" },
  { label: "☕️カフェ", value: "cafe" },
  { label: "🛍ショッピング", value: "shopping" },
  { label: "👚ファッション", value: "fashion" },
  { label: "🎸バンド", value: "band" },
  { label: "🎹クラシック", value: "classical" },
  { label: "🎷ジャズ", value: "jazz" }
];

export const INVALID_NUMBER_INPUTS = ["e", "E", "+", "-"];

export const GIFT_CARDS = {
  AMAZON_GIFT_CARD: "amazon-gift-card",
  APP_STORE_PREPAID_CARD: "app-store-prepaid-card",
  STARBUCKS_GIFT_CARD: "starbucks-gift-card"
};

export const ScreenNames = {
  HOME: "home",
  CREATE_PROFILE: "create_profile",
  EDIT_PROFILE: "edit_profile",
  EDIT_HOBBIES: "edit_hobbies",
  NOT_FOUND: "not_found",
  REDEEM: "redeem",
  HISTORY: "history",
  AUTH_OTP: "otp",
  AUTH_PHONE_NUMBER: "phone_number"
};

export const Paths = {
  HOME: "/",
  REDEEM: "/redeem",
  HISTORY: "/history",
  EDIT: "/edit",
  EDIT_HOBBY: "/edit-hobby"
};

export const Colors = [
  {
    color: "#40B0E0",
    borderColor: "#12A0DD"
  },
  {
    color: "#C0D734",
    borderColor: "#AAC600"
  },
  {
    color: "#E58A57",
    borderColor: "#E26F2E"
  },
  {
    color: "#E2679B",
    borderColor: "#DC4887"
  }
];

export const ModalStatus = {
  UPLOAD: "upload",
  ERROR: "error",
  FEEDBACK: "feedback",
  LOADING: "loading"
};

export const FaceFeedbackStatus = {
  NO_FACE: "no-face",
  TOO_SMALL: "too-small",
  GOOD: "good",
  DEFAULT: "default"
};

export const FaceFeedbackMessages = {
  NO_FACE: "顔を識別できません。",
  TOO_SMALL: "顔のサイズが小さいです",
  GOOD: "顔がわかりやすく写っています。",
  LOADING: "",
  DEFAULT: "default"
};
