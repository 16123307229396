import * as yup from "yup";
import {
  FIELD_REQUIRED,
  INVALID_COMMENT_MESSAGE,
  INVALID_NICKNAME_MESSAGE,
  INVALID_OTP_MESSAGE,
  INVALID_PHONE_NUMBER_MESSAGE,
  INVALID_SHOPID_MESSAGE
} from "../utils/constants";

export const PhoneNumberSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .required(FIELD_REQUIRED)
    .matches(/^0[9876]0\s\d{4}\s\d{4}$/, INVALID_PHONE_NUMBER_MESSAGE)
});

export const OtpSchema = yup.object().shape({
  otp: yup.string().required(FIELD_REQUIRED).matches(/\d{6}/, INVALID_OTP_MESSAGE)
});

export const ProfileSchema = yup.object().shape({
  nickname: yup
    .string()
    .required(FIELD_REQUIRED)
    .matches(/[亜-熙ぁ-んァ-ヶ0-9a-zA-Z]{1,16}/, INVALID_NICKNAME_MESSAGE),
  comment: yup
    .string()
    .required(FIELD_REQUIRED)
    .matches(/[亜-熙ぁ-んァ-ヶ0-9a-zA-Z]+/, INVALID_COMMENT_MESSAGE)
});
