export const formatPhoneNumber = (userInput: string): string => {
  console.log(userInput);
  const validatedString = `+81${userInput
    .replace(/\(|\)|\s|-/gi, "")
    .split("")
    .slice(1)
    .join("")}`;
  console.log(validatedString);
  return validatedString;
};
