import * as React from "react";
import {
  BoolStateFunction,
  KeyValueObject,
  ObjectStateFunction,
  StringStateFunction
} from "../types";
import { firebaseAnalytics, firebaseAuth } from "../config/firebase";

interface AuthContextInterface {
  user: any;

  setUser: ObjectStateFunction;

  globalLoading: boolean;

  setGlobalLoading: BoolStateFunction;

  originPath: string;

  setOriginPath: StringStateFunction;
}

const authContextDefaultValue: AuthContextInterface = {
  user: {},
  setUser: () => {
    // this is intentional for initializing context
  },
  globalLoading: true,
  setGlobalLoading: () => {
    // this is intentional for initializing context
  },
  originPath: "",
  setOriginPath: () => {
    // this is intentional for initializing context
  }
};

const AuthContext = React.createContext<AuthContextInterface>(authContextDefaultValue);

const AuthProvider: React.FC = ({ children }) => {
  const [globalLoading, setGlobalLoading] = React.useState<boolean>(true);
  const [user, setUser] = React.useState<KeyValueObject>({ uid: "" });
  const [originPath, setOriginPath] = React.useState<string>("");

  React.useEffect(() => {
    firebaseAuth.onAuthStateChanged((result) => {
      if (result) {
        firebaseAnalytics.setUserId(result.uid);
        if (result.uid !== user.uid) {
          setUser(result);
          setGlobalLoading(false);
        } else {
          setGlobalLoading(false);
        }
      } else {
        setGlobalLoading(false);
      }
    });
  });

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        globalLoading,
        setGlobalLoading,
        originPath,
        setOriginPath
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
