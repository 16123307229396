import * as React from "react";
import PuffLoader from "react-spinners/PuffLoader";
import "./styles.sass";

const LoadingLogoPage = (): JSX.Element => {
  return (
    <div className="LoadingLogoPage">
      <img
        src={`${process.env.PUBLIC_URL}/images/respo_logo.png`}
        alt="respo logo"
        className="LoadingLogoPage-logo"
      />
      <PuffLoader color="#00B7CE" />
    </div>
  );
};

export default LoadingLogoPage;
